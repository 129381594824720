<template>
  <div class="resources">
    <div class="page-title">Resources</div>
    <div class="resources__content">
      <div class="filters">
        <v-chip-group
            active-class="primary--text"
            column
        >
          <v-chip
              v-for="tag in tags"
              :key="tag"
              @click="chooseFilter(tag)"
          >
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </div>
      <div class="content">
        <v-text-field
            class="flex-inherit"
            label="Search"
            prepend-icon="fal fa-search"
            v-model="filterText"
            dense
            hide-details
        ></v-text-field>
        <v-list class="resources-list">
          <v-list-item
              v-for="resource in filteredResources"
              :key="resource.title"
          >
            <v-list-item-avatar>
              <v-icon>{{ resource.type === 'video' ? 'fal fa-video' : 'fal fa-file-pdf' }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="resource.title"></v-list-item-title>

              <v-list-item-subtitle v-text="resource.description"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                  icon
                  color="deep-purple lighten-2"
                  :href="resource.source"
                  target="_blank"
              >
                <v-icon>{{ resource.type === 'video' ? 'fal fa-eye' : 'fal fa-download' }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>

    </div>
  </div>
</template>

<script>
import { db } from "@/helpers/firebase";

export default {
  name: 'PrivateResources',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'EMS Bio Resources',
    meta: [
      {
        name: 'description',
        content: 'EMS Bio Resources to quickly get you informational documents.'
      },
    ],
    // all titles will be injected into this template
    titleTemplate: '%s'
  },
  props: {},
  data() {
    return {
      resources: [],
      chosenFilter: null,
      filterText: '',
    };
  },
  methods: {
    chooseFilter(filter) {
      if (filter === this.chosenFilter) {
        this.chosenFilter = null;
      } else {
        this.chosenFilter = filter;
      }
    },
    // couldn't get this to work with cors
  //   download(resource) {
  //     const xhr = new XMLHttpRequest();
  //     xhr.responseType = 'blob';
  //     xhr.onload = function () {
  //       const blob = xhr.response;
  //       const link = document.createElement('a');
  //       link.href = URL.createObjectURL(blob);
  //       link.download = resource.title;
  //       link.click();
  //       URL.revokeObjectURL(link.href);
  //     };
  //     xhr.open('GET', resource.source);
  //     xhr.send();
  //   },
  },
  computed: {
    filteredResources() {
      if (!this.chosenFilter && !this.filterText) return this.resources;

      return this.resources.filter(resource => {
        let matchesSearch = true;
        let hasTag = true;

        if (this.filterText) {
          matchesSearch = (resource?.title?.toLowerCase().includes(this.filterText?.toLowerCase()) || resource?.description?.toLowerCase().includes(this.filterText?.toLowerCase()))
        }

        if (this.chosenFilter) {
          hasTag = resource.tags.includes(this.chosenFilter)
        }

        return hasTag && matchesSearch;
      })
    },
    tags() {
      const tags = {};
      this.resources.forEach(resource => {
        resource.tags.forEach(tag => {
          tags[tag]= true
        })
      });

      return Object.keys(tags);
    },
  },
  created() {
    db.collection('resources').get().then(querySnapshot => {

      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        this.resources.push(doc.data());
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.resources {
  padding: 1rem;
  &__content {
    display: flex;
    .content {
      display: flex;
      flex-direction: column;
      width: 600px;
      .flex-inherit {
        flex: inherit;
      }
    }
  }
  .icon-img {
    object-fit: cover;
    max-width: 50px;
  }
  .resource {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 250px;
    margin: 1rem;
    ::v-deep {
      .v-card__title, .v-card__text {
        max-width: 254px;
        word-break: break-word;
        font-size: 18px;
      }
    }
  ;
  }
  .filters {
    display: flex;
    flex-direction: column;
    width: 200px;
  }
  .page-title {
    font-size: 30px;
  }
  .resources-list {
    max-height: calc(100vh - 180px);
    overflow: auto;
  }
}
</style>
